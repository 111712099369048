<template>
  <div class="flex h-screen bg-gray-200 font-roboto">
    <Sidebar/>

    <div class="flex-1 flex flex-col overflow-hidden">
      <Header/>

      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
        <div class="mx-auto py-8">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import Header from '@/components/dashboard/Header'
import Sidebar from '@/components/dashboard/Sidebar'

export default {
    name: "DashboardLayout",
    components: { 
        Sidebar,
        Header
    }
}
</script>