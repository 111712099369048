<template>
  <header
    class="flex justify-between items-center py-4 px-6 bg-white border-b-2 border-blue-500"
  >
    <div class="flex items-center">
      <button
        @click="toggleSidebar"
        class="text-gray-500 focus:outline-none lg:hidden"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>

    <div class="flex items-center">
      <div class="relative">
        <button
          @click="toggleDropdown"
          class="relative z-10 block h-10 w-10 rounded-full overflow-hidden focus:outline-none"
        >
          <faIcon
            icon="user-tie"
            type="fas"
            class="h-full w-full object-cover text-gray-700 text-xl"
            >
          </faIcon>
        </button>

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 h-full w-full z-10"
        ></div>

        <div
          v-show="dropdownOpen"
          class="absolute right-0 mt-2 py-2 bg-white rounded-lg shadow-xl z-20 text-left border border-gray-200 whitespace-no-wrap"
        >
          <router-link
            to="/mi-perfil"
            class="duration-200 block px-6 py-2 text-md text-gray-700 hover:bg-blue-500 hover:text-white"
            >
              <faIcon
              icon="address-card"
              type="fas"
              class="text-md mr-2"
              >
              </faIcon>
              Mi perfil
            </router-link
          >
          <router-link
            to="/mi-clave"
            class="duration-200 block px-6 py-2 text-md text-gray-700 hover:bg-blue-500 hover:text-white"
            >
              <faIcon
              icon="key"
              type="fas"
              class="text-md mr-2"
              >
              </faIcon>
              Cambiar contraseña
            </router-link
          >
          <a
            href="#"
            class="duration-200 block px-6 py-2 text-md text-gray-700 hover:bg-blue-500 hover:text-white"
            @click="logout"
            >
              <faIcon
              icon="sign-out-alt"
              type="fas"
              class="text-md mr-2"
              >
              </faIcon>
              Cerrar sesión
            </a
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import store from "@/store/index"
export default {
    name: "Header",
    data(){
        return {
            dropdownOpen: false
        }
    },
    computed: {
      sidebarOpen() {
        return store.getters.sidebarOpen
      }
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen
        },
        toggleSidebar() {
          store.dispatch("toggleSidebar")
        },
        logout() {
          store.dispatch("auth/logout")
        }
    }
}
</script>